<template>
    <div class="scroe">
        <div class="nav-topbar">
            <div class="mobiles-nav-list">
                <a class="nav-backs" @click="backs()"> 返回 </a>
            </div>
            <div class="nav-list">
                <a class="nav-backs" @click="backs()"> 返回 </a>
                <a class="nav-items">评分 </a>
            </div>
        </div>
        <div class="centers-el">
            <div class="editor-center">
                <el-card :body-style="{ padding: '10px' }">
                    <div class="inputs" style="margin: 12px auto;">
                        <el-rate v-model="value" @change="changValue()" allow-half show-score text-color="#ff9900"
                            :score-template="`${value * 2}分`">
                        </el-rate>
                    </div>
                    <div class="inputs" style="margin: 12px auto;">
                        <el-input v-model="score" placeholder="请输入分数" @input="changeInput()"></el-input>
                    </div>
                    <div>
                        <el-input type="textarea" :rows="2" placeholder="请输入评论" v-model="textarea">
                        </el-input>
                    </div>
                    <div class="inputs" style="margin: 12px auto;">
                        <el-button size="mini" type="warning" @click="addScore()">提交分数</el-button>
                    </div>
                </el-card>
            </div>
            <div class="editor-center">
                <div class="list-item" v-for="(item, index) in List">
                    <div class="user-infos">
                        <el-avatar :src="baseUrls + item.avatar" shape="circle" :size="38" fit="contain"
                            style="float: left;"></el-avatar>
                        <div class="user-nickname">
                            {{ item.nickname }}
                        </div>
                    </div>
                    <div class="score">评分：{{ item.score }}</div>
                    <div class="maincontent" v-html="item.maincontent"></div>
                </div>
            </div>
            <div class="page-el">
                <el-pagination background @current-change="handleCurrentChange" :current-page="currentPage"
                    :page-size="pageSize" layout="total,prev,pager,next" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "scroe",
    data() {
        return {
            id: this.$route.params.id,
            List: [],
            value: 0.00,
            score: 0,
            textarea: '',
            total: 1,
            baseUrls: 'https://www.sosunlive.com',
            pageSize: 12,
            currentPage: 1,
            page: 1,
        }
    },
    created() {
        let self = this
        self.init()
    },
    methods: {
        addScore() {
            let self = this
            if (!self.textarea) {
                self.$message.error("请您填入评论")
                return
            }
            if (self.score == 0) {
                self.$message.error("请您填入评分")
                return
            }
            this.$api.mall.addScore({
                maincontent: self.textarea,
                score: self.score,
                media_id: self.id,
            }).then(res => {
                if (res.code == 1) {
                    self.$message.success("成功")
                    self.textarea=''
                    self.score=0
                    self.value=0
                    self.init()
                } else {
                    self.$message.error("失败")
                }
            });
        },
        changValue() {
            let self = this
            self.score = (parseFloat(self.value) * 2)
        },
        changeInput() {
            let self = this
            self.value = (parseFloat(self.score) / 2)
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            document.documentElement.scrollTop = 0;
            this.list = [];
            this.$api.mall
                .getScore({
                    page: val,
                    limit: 12,
                    id: this.id,
                })
                .then((ress) => {
                    let res = ress.data;
                    this.List = res.data.data;
                    this.total = res.data.total;
                });
        },
        init() {
            let self = this
            this.$api.mall.getScore({
                page: 1,
                limit: 12,
                id: this.id,
            }).then((ress) => {
                let res = ress.data;
                this.List = res.data.data;
                this.total = res.data.total;
            })
        },
        backs() {
            history.go(-1);
        },
    }
}
</script>

<style lang="scss" scoped>
@media (max-width: 8000px) and (min-width: 1024px) {
    .scroe {
        width: 100%;
        overflow: hidden;
        margin: 0px auto;
        padding: 0px;

        .page-el {
            width: 100%;
            overflow: hidden;
            padding: 0px;
            margin: 10px auto;
        }

        .centers-el {
            width: 630px;
            overflow: hidden;
            margin: 48px auto auto auto;
            padding-bottom: 108px;

            .editor-center {
                width: 100%;
                background: #fff;
                box-sizing: border-box;
                padding: 10px;
                overflow: hidden;
                margin: 10px auto auto auto;

                .list-item {
                    width: 100%;
                    overflow: hidden;
                    border-bottom: 1px dashed #ffe;

                    .user-infos {
                        width: 100%;
                        overflow: hidden;
                        box-sizing: border-box;
                        margin: 10px auto;
                        padding: 0px;

                        .user-nickname {
                            //min-width: 88px;
                            overflow: hidden;
                            line-height: 38px;
                            margin-left: 8px;
                            text-align: center;
                            float: left;
                        }
                    }


                    .score {
                        line-height: 28px;
                    }
                }

                .inputs {
                    width: 100%;
                    overflow: hidden;
                    margin: 10px auto;

                    .el-radio {
                        display: inline-block;
                        margin-top: 12px;
                    }
                }
            }

        }

        .nav-topbar {
            width: 100%;
            overflow: hidden;
            background: #ffe url('/imgs/fade.png') top center repeat-x;
            position: fixed;
            top: 0px;
            left: 0px;
            z-index: 10;

            .mobiles-nav-list {
                display: none;
            }

            .nav-list {
                width: 630px;
                overflow: hidden;
                margin: 0px auto;

                .searchTexts {
                    width: 50%;
                    float: right;
                    overflow: hidden;
                    margin: 6px auto;
                }

                .nav-backs {
                    text-decoration: none;
                    padding: 1px;
                    display: block;
                    font-size: 14px;
                    margin-left: 6px;
                    font-weight: 400;
                    overflow: hidden;
                    color: maroon;
                    line-height: 42px;
                    text-align: center;
                    float: left;
                    cursor: pointer;
                }

                .nav-items {
                    text-decoration: none;
                    padding: 1px;
                    display: block;
                    font-size: 14px;
                    margin-left: 6px;
                    font-weight: 400;
                    overflow: hidden;
                    color: maroon;
                    line-height: 42px;
                    text-align: center;
                    float: left;
                }

                .nav-backs:hover {
                    color: red !important;
                    text-decoration: underline;
                }
            }
        }

    }
}

@media (max-width: 1024px) and (min-width: 320px) {
    .scroe {
        width: 100%;
        overflow: hidden;
        margin: 0px auto;
        padding: 0px;

        .page-el {
            width: 100%;
            overflow: hidden;
            padding: 0px;
            margin: 10px auto;
        }

        .centers-el {
            width: 100%;
            box-sizing: border-box;
            overflow: hidden;
            margin: 48px auto auto auto;
            padding-bottom: 108px;

            .editor-center {
                width: 100%;
                background: #fff;
                box-sizing: border-box;
                padding: 10px;
                overflow: hidden;
                margin: 10px auto auto auto;

                .list-item {
                    width: 100%;
                    overflow: hidden;
                    border-bottom: 1px dashed #ffe;

                    .user-infos {
                        width: 100%;
                        overflow: hidden;
                        box-sizing: border-box;
                        margin: 10px auto;
                        padding: 0px;

                        .user-nickname {
                            //min-width: 88px;
                            overflow: hidden;
                            line-height: 38px;
                            margin-left: 8px;
                            text-align: center;
                            float: left;
                        }
                    }


                    .score {
                        line-height: 28px;
                    }
                }

                .inputs {
                    width: 100%;
                    overflow: hidden;
                    margin: 10px auto;

                    .el-radio {
                        display: inline-block;
                        margin-top: 12px;
                    }
                }
            }

        }

        .nav-topbar {
            width: 100%;
            overflow: hidden;
            position: fixed;
            top: 0px;
            left: 0px;
            z-index: 10;
            background: url('/imgs/fade.png') top center repeat-x;

            .mobiles-nav-list {
                width: 100%;
                overflow: hidden;
                box-sizing: border-box;
                padding: 5px;
                margin: 0px auto;

                .nav-backs {
                    text-decoration: none;
                    padding: 1px;
                    display: block;
                    font-size: 14px;
                    margin-left: 10px;
                    font-weight: 400;
                    overflow: hidden;
                    color: maroon;
                    line-height: 30px;
                    text-align: center;
                    float: left;
                }
            }

            .nav-list {
                display: none;
            }
        }
    }
}
</style>