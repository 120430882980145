/**
 * 路由封装
 * 路由懒加载方式一：
 *    1. component: resolve => require(['../pages/Index.vue'],resolve)  // 路由懒加载
 * 路由懒加载方式二：
 *    1. 需要添加 syntax-dynamic-import (opens new window)插件，才能使 Babel 可以正确地解析语法,`cnpm install --save-dev @babel/plugin-syntax-dynamic-import`
 *    2. component: () => import('../pages/Cart.vue'), // 路由懒加载
 */
// 导入vue和vue-router
import Vue from 'vue'
import Router from 'vue-router'
// 导入页面组件
// import Login from '../pages/Login'
import Home from '../pages/Home'
import Index from '../pages/Index'
// import Product from '../pages/Product'
import Detail from '../pages/Detail'
import Receipt from '../pages/Receipt'
import Reply from '../pages/Reply'
import Speak from '../pages/Speak'
import Posted from '../pages/Posted'
import SpeakReply from '../pages/SpeakReply'
import StickReply from '../pages/StickReply'
import SubmitRes from '../pages/SubmitRes'
import ResList from '../pages/ResList'
import myCollects  from '../pages/myCollects'
import myGleans  from '../pages/myGleans'
import myFollows  from '../pages/myFollows'
import myFans  from '../pages/myFans'
import mySpaces  from '../pages/mySpaces'
import PostMovie  from '../pages/PostMovie'
import myMovie  from '../pages/myMovie'
import EditMovie  from '../pages/EditMovie'
import EditSticks  from '../pages/EditSticks'
import Commit  from '../pages/Commit'
import Score  from '../pages/Score'
// import Cart from '../pages/Cart'
// import Order from '../pages/Order'
// import OrderConfirm from '../pages/OrderConfirm'
// import OrderList from '../pages/OrderList'
// import OrderPay from '../pages/OrderPay'
// import Alipay from '../pages/Alipay'
// 加载路由插件
Vue.use(Router)
// 导出路由
export default new Router({
  // 路由规则
  routes: [
    // 首页
    {
      path: '/',
      name: 'home',
      component: Home,
      redirect: '/index', // 重定向
      // 子路由
      children: [
        {
          path: 'index',
          name: 'index',
          component: Index,
        },
        {
          path: 'product/:id', // 动态路由
          name: 'product',
          // component: Product,
          // component: resolve => require(['../pages/Product.vue'],resolve), // 路由懒加载
          component: () => import('../pages/Product.vue') // 路由懒加载
        }
      ]
    },
    {
      path: '/detail/:id',
      name: 'detail',
      component: Detail,
    },
    {
      path: '/Score/:id',
      name: 'Score',
      component: Score,
    },
    {
      path: '/EditMovie/:id',
      name: 'EditMovie',
      component: EditMovie,
    },
    {
      path: '/EditSticks/:id',
      name: 'EditSticks',
      component: EditSticks,
    },
    {
      path: '/SubmitRes/:id',
      name: 'SubmitRes',
      component: SubmitRes,
    },
    {
      path: '/mySpaces/:id/:token',
      name: 'mySpaces',
      component: mySpaces,
    },
    {
      path: '/Commit',
      name: 'Commit',
      component: Commit,
    },
    {
      path: '/myMovie',
      name: 'myMovie',
      component: myMovie,
    },
    {
      path: '/myCollects',
      name: 'myCollects',
      component: myCollects,
    },
    {
      path: '/myFollows',
      name: 'myFollows',
      component: myFollows,
    },
    {
      path: '/myFans',
      name: 'myFans',
      component: myFans,
    },
    {
      path: '/myGleans',
      name: 'myGleans',
      component: myGleans,
    },
    {
      path: '/ResList',
      name: 'ResList',
      component: ResList,
    },
    {
      path: '/Receipt/:id',
      name: 'Receipt',
      component: Receipt,
    },
    {
      path: '/Reply',
      name: 'Reply',
      component: Reply,
    },
    {
      path: '/PostMovie',
      name: 'PostMovie',
      component: PostMovie,
    },
    {
      path: '/Speak',
      name: 'Speak',
      component: Speak,
    },
    {
      path: '/SpeakReply',
      name: 'SpeakReply',
      component: SpeakReply,
    },
    {
      path: '/StickReply',
      name: 'StickReply',
      component: StickReply,
    },
    {
      path: '/Posted',
      name: 'Posted',
      component: Posted,
    },
    // 登录
    {
      path: '/login',
      name: 'login',
      // component: Login
      component: () => import('../pages/Login.vue') // 路由懒加载
    },
    // 购物车
    {
      path: '/cart',
      name: 'cart',
      // component: Cart
      component: () => import('../pages/Cart.vue') // 路由懒加载
    },
    // 订单
    {
      path: '/community',
      name: 'community',
      component: () => import('../pages/Community.vue'),
    }
  ]
})